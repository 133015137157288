import React from "react"
import Layout from "../../components/common/Layout"
import Cursor from "../../components/common/Cursor"
import Learn from "../../components/common/Learn"
import CareersLink from "../../components/common/CareersLink"
import Footer from "../../components/common/Footer"
import Kv from "../../components/common/Kv"
import Personality from "../../components/section/vision/Personality"

export default function Vision() {
  let title = "OUR MISSION"
  let description = "熱狂的なファンをつくる。"
  let page = "vision"
  let headTtl = "OUR MISSION"
  let blockTtl = "熱狂的な<br />ファンをつくる。"
  let blockTxt =
    "あなたがフェスのライブ会場でいまから出演するバンドのメンバーだと想像してください。 <br />" +
    "ステージの観客はどんな人であって欲しいですか？ <br />" +
    "あなたのバンドを知ってる人？ <br />" +
    "次のバンドの出番の時間調整のために時間つぶしにあなたのステージに来た人？ <br />" +
    "それとも、あなたのバンドが大好きな熱狂的なファン？ <br />" +
    "もちろん、熱狂的なファンがたくさん会場にいたら盛り上がりそうだし、観客もバンドもきっと楽しいですよね。 <br />" +
    "みんながもっと楽しくなって最高の時間を過ごしてほしい。 <br />" +
    "だから熱狂的なファンをつくるんです。 <br />" +
    "なんだか、考えただけでワクワクしませんか？"
  let dark = "js-scroll__dark"
  let kvSrc = "/asset/img/vision/kv.jpg"
  let kvSrc2x = "/asset/img/vision/kv@2x.jpg"
  let kvSrcWebp = "/asset/img/vision/kv.jpg.webp"
  let kvSrcWebp2x = "/asset/img/vision/kv@2x.jpg.webp"
  let kvAlt = "サラダの食材"

  return (
    <Layout title={title} description={description}>
      <div id="page">
        <div id="vision" className="js-page">
          <main id="main">
            <div id="contents" className="contents">
              <Cursor />
              <Kv
                headTtl={headTtl}
                blockTtl={blockTtl}
                blockTxt={blockTxt}
                dark={dark}
                page={page}
                kvSrc={kvSrc}
                kvSrc2x={kvSrc2x}
                kvSrcWebp={kvSrcWebp}
                kvSrcWebp2x={kvSrcWebp2x}
                kvAlt={kvAlt}
              />
              <Personality />
              <Learn backgroundColor={"gradation"} />
              <CareersLink />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}
