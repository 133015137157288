import React from "react"

const Personality = () => {
  let personalityVisions = [
    {
      num: "01",
      ttl: "LOVE WHAT YOU DO",
      sub: "好きになる。",
      item1: "・人とデータに関心をもとう",
      item2: "・学ぶことをやめず、成長し続けよう",
    },
    {
      num: "02",
      ttl: "CONNECT WITH HEART",
      sub: "心でつながる。",
      item1: "・パートナーとお客さまの笑顔を増やそう",
      item2: "・正しいことを、オープンにやろう",
    },
    {
      num: "03",
      ttl: "GO BEYOND EXPEC<span class='u-none__pc'>-</span>TATION",
      sub: "期待を超越する。",
      item1: "・ひとつ余分に実行しよう",
      item2: "・「当たり前」の基準を上げよう",
    },
  ]

  return (
    <section className="p-vision-personality">
      <div className="p-vision-personality__wrapper">
        <div className="p-contents p-contents__personality">
          <div className="p-contents__wrapper">
            <h2 className="c-ttl__en p-contents__en">OUR VALUE</h2>
            <div className="p-contents__block">
              <h3 className="c-ttl__main p-contents__ttl">
                私たちの立ち向かう姿勢
              </h3>
            </div>
          </div>
          <div className="p-contents__content">
            <ul className="p-contents__content-list">
              {personalityVisions.map(data => {
                return (
                  <li className="p-contents__content-item" key={data.num}>
                    <div className="p-contents__content-item-header">
                      <span className="c-ttl__en p-contents__content-item-header-num">
                        {data.num}
                      </span>
                      <h4
                        className="p-contents__content-item-header-ttl"
                        dangerouslySetInnerHTML={{
                          __html: data.ttl,
                        }}
                      />
                      <span className="p-contents__content-item-header-sub">
                        {data.sub}
                      </span>
                    </div>
                    <div className="p-contents__content-item-body">
                      <ul className="p-contents__content-item-body-list">
                        <li className="p-contents__content-item-body-item">
                          {data.item1}
                        </li>
                        <li className="p-contents__content-item-body-item">
                          {data.item2}
                        </li>
                      </ul>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Personality
