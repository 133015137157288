import React, { Component } from "react"
import ArrowYDown from "../common/ArrowYDown"
import Img from "./Img"

class Kv extends Component {
  render() {
    return (
      <section
        className={`p-common-kv p-${this.props.page}-kv ${this.props.under}`}
      >
        <div className="p-common-kv__wrapper">
          <div className="p-common-kv__head">
            <span className="p-common-kv__head__parent">
              {this.props.headParent}
            </span>
            <h1 className="p-common-kv__head-ttl">
              <div dangerouslySetInnerHTML={{ __html: this.props.headTtl }} />
            </h1>
          </div>
          <div className="p-common-kv__block">
            <div className="p-common-kv__content">
              <h2 className="c-ttl__main p-common-kv__content-ttl">
                <div
                  dangerouslySetInnerHTML={{ __html: this.props.blockTtl }}
                />
              </h2>
              <p
                className="c-txt__main p-common-kv__content-txt"
                dangerouslySetInnerHTML={{ __html: this.props.blockTxt }}
              />
            </div>
          </div>
          <button
            className="js-hover p-common-kv__arrow"
            data-updown-trigger="kv"
            data-hover-in-time="600"
            aria-label="スクロールダウンボタン"
          >
            <ArrowYDown />
          </button>
          <div
            className={`${this.props.dark} p-common-kv__img`}
            data-updown-target="kv"
          >
            {/* <picture>
              <div dangerouslySetInnerHTML={{ __html: this.props.imgSp }} />
              <img
                className="c-img__def p-common-kv__img-item p-common-kv__img-item__trigger p-common-kv__img-item__careers__restaurant"
                src={`${this.props.imgSrc1}`}
                srcSet={`${this.props.imgSrc1} 1x, ${this.props.imgSrc2} 2x`}
                alt={`${this.props.imgAlt}`}
                loading="lazy"
              />
            </picture> */}
            <Img
              src={this.props.kvSrc}
              src2x={this.props.kvSrc2x}
              webpSrc={this.props.kvSrcWebp}
              webpSrc2x={this.props.kvSrcWebp2x}
              srcSp={this.props.kvSrcSp}
              srcWebpSp={this.props.kvSrcWebpSp}
              alt={this.props.kvAlt}
            />
          </div>
        </div>
      </section>
    )
  }
}

export default Kv
